import dynamic from 'next/dynamic'

export const Macros = {
  Articlebox: dynamic(() => import('./Articlebox').then(mod => mod.Articlebox)),
  MultipleProductBoxes: dynamic(() =>
    import('./MultipleProductBoxes').then(mod => mod.MultipleProductBoxes)
  ),
  MultipleProductBoxesBasedonActivityid: dynamic(() =>
    import('./MultipleProductBoxesBasedonActivityid').then(
      mod => mod.MultipleProductBoxesBasedonActivityid
    )
  ),
  MultipleProductBoxesByCategoryFilterPath: dynamic(() =>
    import('./MultipleProductBoxesByCategoryFilterPath').then(
      mod => mod.MultipleProductBoxesByCategoryFilterPath
    )
  ),
  Productbox: dynamic(() => import('./Productbox').then(mod => mod.Productbox)),
  NorwegianRewards: dynamic(() => import('./NorwegianRewards').then(mod => mod.NorwegianRewards)),
  PricingRulePreRegistration: dynamic(() =>
    import('./PricingRulePreRegistration').then(mod => mod.PricingRulePreRegistration)
  ),
  CampaignProductListing: dynamic(() =>
    import('./CampaignProductListing').then(mod => mod.CampaignProductListing)
  ),
}
