import React from 'react'
import {
  domParser,
  NodeParser,
} from '@dustin-web/content-components/src/macro-content/node-parsers'
import { LinkToDialog } from './form-link-parser-effects'

export const formLinkParser: NodeParser = (node, additionalMacros, additionalNodeParsers) => {
  if (
    node.type === 'tag' &&
    node.name === 'a' &&
    node.attribs['href']?.includes('service/contact/')
  ) {
    const formIdString = node.attribs['href'].split('/').at(-1)
    const formId = parseInt(formIdString || '0')
    if (formId) {
      const link = node
      const href = link.attribs['href'] || ''
      const children = domParser(link.children, additionalMacros, additionalNodeParsers)

      return (
        <LinkToDialog formId={formId} href={href}>
          {children}
        </LinkToDialog>
      )
    }
  }

  return
}
