'use client'

import React, { useState } from 'react'
import { ModalDialog, ModalDialogContent } from '@dustin-web/microsite-components/src/modal-dialog'
import { Form } from './form'
import { GetFormQuery, GetFormQueryVariables } from 'src/graphql/generated/graphql-types'
import { useGraphql } from '@dustin-web/microsite-data/src/hooks/use-graphql'
import { GetForm } from 'src/graphql/get-form.graphql'

export const LinkToDialog = ({
  formId,
  href,
  children,
}: {
  formId: number
  href: string
  children: React.ReactNode
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <a
        href={href}
        onClick={e => {
          e.preventDefault()
          setOpen(true)
        }}>
        {children}
      </a>
      <ModalDialog show={open} handleClose={() => setOpen(false)}>
        <ModalDialogContent className="u-pt-24">
          {open ? <FormWrapper id={formId} onClose={() => setOpen(false)} /> : <></>}
        </ModalDialogContent>
      </ModalDialog>
    </>
  )
}

const FormWrapper = ({ id, onClose }: { id: number; onClose: () => void }) => {
  const { data, error } = useGraphql<GetFormQuery, GetFormQueryVariables>(GetForm, {
    variables: { formId: id },
  })

  const form = data?.customerServiceInquiry

  if (error) {
    // eslint-disable-next-line no-console
    console.log({ error, form })
    return <div>Error: {error?.message ?? 'Not found'}</div>
  }

  if (!form) {
    return null
  }

  return <Form id={id} form={form} onClose={onClose} />
}
