import React from 'react'
import { BasicMacroContent } from '@dustin-web/content-components/src/macro-content'
import { Macros } from '.'
import { formLinkParser } from '../forms/form-link-parser'
import { formOptionValueParser } from '../forms/form-option-value-parser'

type Props = {
  content: string
}

export const MacroContent = (props: Props) => {
  return (
    <BasicMacroContent
      content={props.content}
      additionalMacros={Macros}
      additionalNodeParsers={[formLinkParser, formOptionValueParser]}
    />
  )
}

export default MacroContent
