import React from 'react'
import { attributesToProps } from 'html-react-parser'
import {
  domParser,
  NodeParser,
} from '@dustin-web/content-components/src/macro-content/node-parsers'

export const formOptionValueParser: NodeParser = (
  node,
  additionalMacros,
  additionalNodeParsers
) => {
  if (node.type === 'tag' && node.name === 'option' && node.attribs.value) {
    const link = node

    const value = link.attribs['value']
    const props = attributesToProps(link.attribs)
    const children = domParser(link.children, additionalMacros, additionalNodeParsers)

    return (
      <option {...props} value={value}>
        {children}
      </option>
    )
  }

  return
}
